<template>
  <div>
   


    <div class="maxbox flex-space-between">
      <div class="left">
        <div class="leftflex">
          <div class="logobox"><img src="@/assets/page/qydt.png" alt="" /></div>
          <div class="leftmxabox">
            莱茵河对极致美学的追求契合，尊重自然与热爱艺术的灵魂相碰撞，紧跟流行趋势致力于引领时尚潮流，让每一个产品走近艺术。
          </div>
        </div>
      </div>

      <div class="right">
      
       <div class="rightmxabox">
       <template v-if="listdata.length!=0">
        <div
          class="itembox flex-space-between"
          @click="itemclick(item.id)"
         
          v-for="(item, index) in listdata"
          :key="index"
        >
          <div class="leftbox">
            <div class="text1">{{ item.title }}</div>
            <div class="text2">阅读量：{{item.views}}</div>
            <div class="text3">
              {{ item.subtitle }}
            </div>
         
            
            <img
              :class="activeindex == index ? 'activeclass ' : 'text4 '" src="@/assets/page/lvjt.png" alt=""/>
          </div>
          <div class="rigbox">
            <img
            :src="item.image"
              alt=""
            />
          </div>
        </div>
       </template>
          <!-- 缺失页 -->
        <div class="maxqsbox" v-else>
          <div class="qsbox">
            <img src="@/assets/page/qst.png" alt="">
            <div>暂无数据</div>
          </div>
        </div>
      </div>
      <div class="fenyebox">
          <el-pagination
            background
            class="fenye"
            @current-change="handleCurrentChange"
            :current-page="page.page"
            layout="prev, pager, next"
            :page-size="page.per_page"
            :total="page.total"
          >
        </el-pagination>
      
       </div>
      </div>

    </div>
  </div>
</template>

<script>
import {trends} from '../../service/request/homepage';
export default {
  data() {
    return {
      activeindex: null,
      listdata:[],
      page: {
        page:1,
        last_page: 0,
        per_page: 8,
        total: 1,
      },
    }
  },

  components: {},
  mounted() {
    this.trends()
  },
  methods: {
    // 初始数据
    trends() {
      trends({
        page:this.page.page
      }).then(res => {
        console.log(res, '2');
        this.listdata=res.msg.data
      })
    },

    mouover(index) {
      this.activeindex = index
    },
    monve() {
      this.activeindex = null
    },
    itemclick(id) {
      // alert(1)
      this.$router.push({
        path: '/enterpriseinfo',
        query: {
          id
        }
      })
    },
    handleCurrentChange(e) {
      this.page.page = e
      this.trends()
      window.scrollTo(0, 0)
    }
  }
}
</script>
<style scoped lang="less">

.maxqsbox{
  width:1130px;
  display: flex;
  justify-content: center;
  .qsbox{
   margin: 0 auto;
   min-height: 500px;
   text-align: center;
  img{
    width: 112px;
    height: 112px;
  }
  div{
    margin-top: 10px;
    color: #999;
  }

}
}

.toplogo {
  padding-top: 135px;
  margin: 0 85px;
  height: 240px;
  .logobox {
    width: 232px;
    height: 56px;
    img {
      width: 232px;
      height: 56px;
    }
  }
}
.buttombox {
  margin: 38px 85px 0;
  display: flex;
  .leftmxabox {
    width: 239px;
    margin-right: 62px;
    color: #000000;
    font-size: 16px;
  }
  .rightmxabox {
    .itembox {
      cursor: pointer;
      width: 1449px;
      border-bottom: 1px solid #eee;
      .leftbox {
        .text1 {
          font-size: 26px;
          padding-top: 47px;
          color: #000;
        }
        .text2 {
          font-size: 16px;
          color: #999999;
          margin-top: 29px;
        }
        .text3 {
          width: 643px;
          font-size: 16px;
          color: #333333;
          margin-top: 104px;
        }
        .text4 {
          margin-top: 39px;
          width: 52px;
          height: 8px;
          display: none;
        }
        .activeclass {
          margin-top: 39px;
          width: 52px;
          height: 8px;
          display: block;
        }
      }
      .rigbox {
        margin: 62px 0;
        width: 480px;
        height: 316px;
       
        img {
          width: 480px;
          border-radius: 8px;
          height: 316px;
        }
      }
    }
    .itembox:hover .text1 {
      color: #3e907c;
    }
    .itembox:hover .text2 {
      color: #3e907c;
    }
    .itembox:hover .text3 {
      color: #3e907c;
    }
    .itembox:hover .text4 {
      display: block;
    }
  }
}


.maxbox{
  margin: 150px 85px 0;
  .left{
    width: 290px;
    // width: 200px;
    // position: fixed;
    // position: relative;
    .leftflex{
      position: fixed;
      top: 170px;
      left: 85px;
    }
     .logobox {
        width: 232px;
        height: 56px;
        img {
          width: 232px;
          height: 56px;
        }
      }
      .leftmxabox {
        width: 239px;
        margin-top: 20px;
        margin-right: 62px;
        color: #000000;
        font-size: 16px;
      }
  }
  .right{
    .fenyebox{
      width: 1449px;
      height: 100px;
      // position: fixed;
    
      // top: 130px;
      align-items: center;
      background-color: #fff;
      display: flex;
      justify-content: flex-end;
    }
   .rightmxabox {
   
    .itembox {
      cursor: pointer;
      width: 1449px;
      border-bottom: 1px solid #eee;
      .leftbox {
        .text1 {
          font-size: 26px;
          padding-top: 47px;
          color: #000;
        }
        .text2 {
          font-size: 16px;
          color: #999999;
          margin-top: 29px;
        }
        .text3 {
          width: 643px;
          font-size: 16px;
          color: #333333;
          margin-top: 104px;
        }
        .text4 {
          margin-top: 39px;
          width: 52px;
          height: 8px;
          display: none;
        }
        .activeclass {
          margin-top: 39px;
          width: 52px;
          height: 8px;
          display: block;
        }
      }
      .rigbox {
        margin: 62px 0;
        width: 480px;
        height: 316px;
       
        img {
          width: 480px;
          border-radius: 8px;
          height: 316px;
        }
      }
    }
    .itembox:hover .text1 {
      color: #3e907c;
    }
    .itembox:hover .text2 {
      color: #3e907c;
    }
    .itembox:hover .text3 {
      color: #3e907c;
    }
    .itembox:hover .text4 {
      display: block;
    }
    }
   
  }
}
</style>
